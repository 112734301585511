import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tick from "../../assets/succcess.png";

export default function NomineeAdded(props) {
  const handleNomineeAdded = (e) => {
    props.handleChange(e.target.name, e.target.value);
  };
  return (
    <FormControl fullWidth>
      <div className="flex justify-between mt-4">
        <p className="text-white">Add Nominee</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="nomineeAdded"
        onChange={handleNomineeAdded}
        defaultValue="YES"
        value={props.value ? "YES" : "NO"}
      >
        <FormControlLabel value="YES" sx={{color:"white"}} control={<Radio />} label="Yes" />
        <FormControlLabel value="NO" sx={{color:"white"}} control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
}
