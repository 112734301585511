import React, { useEffect, useState } from "react";
import Logo from "../../assets/nirmanData/nirmanLogo.png";
import { Box, Modal } from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
};
const DeleteButtonStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
};

function UserAdmin() {
  const [data, setData] = useState([]);
  const [deleteKycUser, setDeleteKycUser] = useState("");
  const [deleteRekycUser, setDeleteRekycUser] = useState("");
  const [deleteClosureUser, setDeleteClosureUser] = useState("");
  const [deletePhoneNo, setDeletePhoneNo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [kycMsg, setKycMsg] = useState(false);
  const [reKycMsg, setReKycMsg] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createAdmin, setCreateAdmin] = useState({
    name: "",
    username: "",
    password: "",
    roles: {
      rolename: "",
    },
  });
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleDeleteOpen = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  useEffect(() => {
    Data();
  }, []);

  const Data = async () => {
    const token = sessionStorage.getItem("authToken");

    try {
      const response = await fetch(
        "https://ekyc.finovo.tech:5556/api/v1/admin/detail/identifier",
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
      } else {
        console.error(
          "Error fetching data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  const DeleteEntry = async (user_id) => {
    const token = sessionStorage.getItem("authToken");
    try {
      const deleteApi = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/admin/delete/credentials/authority/${user_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(deletePhoneNo),
        }
      );

      if (deleteApi.ok) {
        const updatedData = data.filter((item) => item.userId !== user_id);
        setData(updatedData);
      } else {
        console.error(
          "Error deleting entry:",
          deleteApi.status,
          deleteApi.statusText
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateAdmin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleRole = (e) => {
    setCreateAdmin((prev) => ({
      ...prev,
      roles: {
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("authToken");
    try {
      const createApi = await fetch(
        "https://ekyc.finovo.tech:5556/api/v1/admin/signup/registernew-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(createAdmin),
        }
      );
      // window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setShowModal(false);
    }
  };
  const handleKycDelete = async () => {
    // Check if deleteKycUser is empty or less than 10 digits
    if (deleteKycUser === "" || deleteKycUser.length < 10) {
      setKycMsg(true); // Assuming this sets some error message or state indicating the issue to the user
      // Optionally, you could customize the message based on the specific validation that failed
      if (deleteKycUser === "") {
        console.error("KYC User ID is required");
      } else {
        console.error("KYC User ID must be at least 10 digits long");
      }
      return; // Exit the function if validation fails
    }
  
    const token = sessionStorage.getItem("authToken");
  
    try {
      const deleteKyc = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/kyc/user/details/delete/${deleteKycUser}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Ensure the Authorization header is correctly formatted
            // clientcode: deleteKycUser, // Uncomment if the client code is needed
          },
        }
      );
  
      if (deleteKyc.ok) {
        // Request was successful
        console.log("KYC deletion successful");
        setDeleteKycUser(""); // Clear the input after successful deletion
        toast.success("KYC User Delete Successfully"); // Show success message
      } else {
        // Request failed
        console.error("Failed to delete KYC:", deleteKyc.status, deleteKyc.statusText);
      }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error during KYC deletion:", error);
    }
  };
  const handleReKycDelete = async () => {
    if (deleteRekycUser === "") {
      setReKycMsg(true);
      return; // Add a return statement to exit the function if deleteRekycUser is empty
    }
    const token = sessionStorage.getItem("authToken");
    try {
      const deleteRekyc = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/rekyc/user/delete/by/admin`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
            "clientcode": deleteRekycUser,
          },
        }
      );
  
      if (deleteRekyc.ok) {
        // Request was successful, handle the response or update state as needed
        console.log("ReKYC deletion successful");
        setDeleteRekycUser("");
        toast.success("ReKYC User Delete Successfully");
      } else {
        // Request failed, log the error or handle it appropriately
        console.error("Failed to delete ReKYC:", deleteRekyc.status, deleteRekyc.statusText);
      }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error during ReKYC deletion:", error);
    }
  };
  const handleClosureDelete = async () => {
    if (deleteClosureUser === "") {
      setReKycMsg(true);
      return; // Add a return statement to exit the function if deleteRekycUser is empty
    }
    const token = sessionStorage.getItem("authToken");
    try {
      const deleteRekyc = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/rekyc/closure/delete/by/admin`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
            "clientcode": deleteClosureUser,
          },
        }
      );
  
      if (deleteRekyc.ok) {
        // Request was successful, handle the response or update state as needed
        console.log("ReKYC deletion successful");
        setDeleteRekycUser("");
        toast.success("ReKYC User Delete Successfully");
      } else {
        // Request failed, log the error or handle it appropriately
        console.error("Failed to delete ReKYC:", deleteRekyc.status, deleteRekyc.statusText);
      }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error during ReKYC deletion:", error);
    }
  };

  return (
    <>
      <div className="h-[100vh] overflow-y-auto">
        <div className="flex justify-start">
          <img src={Logo} alt="nirmanLogo" />
        </div>
        <div>
          <table className="w-[90%] mx-auto">
            <thead>
              <tr className="bg-red text-xl">
                <th className="border-2">Name</th>
                <th className="border-2">User Name</th>
                <th className="border-2">Roles</th>
                <th className="border-2">Last Login Time</th>
                <th className="border-2">Creation Time</th>
                <th className="border-2">Login Disabled</th>
                <th className="border-2">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr className="text-lg uppercase text-center" key={idx}>
                  <td className="border-2 p-2">{item.name}</td>
                  <td className="border-2 p-2">{item.username}</td>
                  <td className="border-2 p-2">{item.roles?.join(", ")}</td>
                  <td className="border-2 p-2">{item.lastLoginTime}</td>
                  <td className="border-2 p-2">{item.creationTime}</td>
                  <td className="border-2 p-2">
                    {item.loginDisabled === false
                      ? "False"
                      : item.loginDisabled === true
                        ? "True"
                        : "N/A"}
                  </td>
                  <td className="border-2 p-2">
                    <button
                      className="bg-blue text-white px-6 mx-4 py-2.5 rounded-2xl"
                      onClick={() => DeleteEntry(item.userId)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* ==== */}
        <div className="flex justify-center">
          <div className="ms-6 mt-6">
            <button
              data-modal-target="authentication-modal"
              data-modal-toggle="authentication-modal"
              className="block text-white bg-blue hover:bg-blue focus:ring-4 focus:outline-none focus:ring-blue font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              type="button"
              onClick={handleOpen}
            >
              Create Member
            </button>
          </div>
          <div className="ms-6 mt-6">
            <button
              data-modal-target="authentication-modal"
              data-modal-toggle="authentication-modal"
              className="border text-white bg-red rounded-lg text-sm px-5 py-2.5 text-center"
              type="button"
              onClick={handleDeleteOpen}
            >
              Delete User
            </button>
          </div>
        </div>
      </div>
      {/* create modal  */}
      <>
        <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* Your custom content goes here */}
            <div className="p-1">
              {/* Modal content */}
              <div className=" bg-white rounded-lg">
                {/* Modal header */}
                <div className="flex items-center justify-between p-5 border-b rounded-t">
                  <p className="text-xl font-bold">Create Member</p>
                  <button
                    type="button"
                    className="end-2.5 text-grey bg-transparent rounded-lg text-sm w-8 h-8 ms-auto flex justify-center items-center"
                    data-modal-hide="authentication-modal"
                    onClick={handleClose}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body */}
                <div className="p-4 md:p-5">
                  <form className="space-y-4">
                    <div>
                      <label htmlFor="username" className="mb-2 font-bold">
                        Enter Your Username
                      </label>
                      <input
                        type="text"
                        name="username"
                        value={createAdmin.username}
                        onChange={handleChange}
                        id="username"
                        className="border text-sm rounded-lg w-full p-2.5"
                        placeholder="Enter Your Username"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="name" className="mb-2 font-bold">
                        Enter Your Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={createAdmin.name}
                        onChange={handleChange}
                        id="name"
                        className="border text-sm rounded-lg w-full p-2.5"
                        placeholder="Enter Your Name"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="mb-2 text-sm font-bold"
                      >
                        Enter Your Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={createAdmin.password}
                        onChange={handleChange}
                        id="password"
                        placeholder="Enter Your Password"
                        className="border text-sm rounded-lg w-full p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="role" className="mb-2 text-sm font-bold">
                        Select Your Role
                      </label>
                      <br />
                      <select
                        name="rolename"
                        value={createAdmin.roles.rolename}
                        onChange={handleRole}
                        className="border text-sm rounded-lg w-full p-2.5"
                        required
                      >
                        <option value="" disabled>
                          Select a role
                        </option>
                        <option value="RM">RM</option>
                        <option value="AP">AP</option>
                        <option value="SUBADMIN">SUBADMIN</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      className="w-full bg-blue text-white rounded-lg text-sm px-5 py-2.5 text-center"
                      onClick={handleCreateAdmin}
                    >
                      Create Account
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </>
      {/* delete modal */}
      <>
        <Modal
          open={showDeleteModal}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={DeleteButtonStyle}>
            <div className="flex items-center justify-between p-2 border-b rounded-t">
              <p className="text-xl font-bold">Delete User</p>
              <button
                type="button"
                className="end-2.5 text-grey bg-transparent rounded-lg text-sm w-8 h-8 ms-auto flex justify-center items-center"
                data-modal-hide="authentication-modal"
                onClick={handleDeleteClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 flex justify-around gap-10">
              <div className="flex justify-center flex-col">
                <label className="m-2 font-bold text-center">Enter Mobile No. (KYC)</label>
                <input
                  type="number"
                  className="border text-sm rounded-lg w-[220px] p-2.5 "
                  placeholder="Enter your Mobile No."
                  value={deleteKycUser}
                  onChange={(e) => setDeleteKycUser(e.target.value)}
                />
                {/* {deleteKycUser} */}
                <button
                  className="border text-white bg-red rounded-lg text-sm px-5 py-2.5 text-center mt-3"
                  onClick={handleKycDelete}
                >
                  Delete Kyc User
                </button>
                {kycMsg && (
                  <p className="text-red mt-1">*Please enter Mobile No.</p>
                )}
              </div>
              <div className="flex justify-center flex-col">
              <label className="m-2 font-bold">Enter Client Code (ReKyc)</label>
                <input
                  type="number"
                  className=" border text-sm rounded-lg w-[250px] p-2.5 "
                  placeholder="Enter your Client Code"
                  value={deleteRekycUser}
                  onChange={(e) => setDeleteRekycUser(e.target.value)}
                />
                {/* {deleteRekycUser} */}
                <button
                  className="border text-white bg-red rounded-lg text-sm px-5 py-2.5 text-center mt-3"
                  onClick={handleReKycDelete}
                >
                  Delete ReKyc User
                </button>
                {reKycMsg && (
                  <p className="text-red mt-1">*Plaese enter Client Code</p>
                )}
              </div>
              <div className="flex justify-center flex-col">
              <label className="m-2 font-bold">Enter Client Code (Closure)</label>
                <input
                  type="number"
                  className=" border text-sm rounded-lg w-[250px] p-2.5 "
                  placeholder="Enter your Client Code"
                  value={deleteClosureUser}
                  onChange={(e) => setDeleteClosureUser(e.target.value)}
                />
                {/* {deleteRekycUser} */}
                <button
                  className="border text-white bg-red rounded-lg text-sm px-5 py-2.5 text-center mt-3"
                  onClick={handleClosureDelete}
                >
                  Delete Closure User
                </button>
                {reKycMsg && (
                  <p className="text-red mt-1">*Plaese enter Client Code</p>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    </>
  );
}

export default UserAdmin;
