import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Loader from "../../components/loader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useApi from "../../hooks/useApi";
// import "./styles.scss";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";
import Login from "../../assets/mobileHome/login.png";
import RButton from "../../components/rButton";
// import { useHistory } from 'react-router-dom';
import EKYC from "../../assets/mobileHome/eKyc.png";
import Ekyc from "../../assets/kyc/ekycLogo.png";
import EditButton from "../../assets/email/editButton.png";
import RImg from "../../components/rImg";
import { ThankYou } from "../thankYou";

function MobileLogin(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();
  const [showThankYou, setShowThankYou] = useState(false);



  const reDirFn = async () => {
    // const history = useHistory();
    const pathName = window.location.pathname;
    if (pathName === "/legality/callback") {
      const urlParams = new URLSearchParams(window.location.search);
      const phoneNumber = urlParams.get("phoneNumber");
      const documentId = urlParams.get("documentId");
      const isRekyc = urlParams.get("isrekyc");

      if (phoneNumber && documentId) {
        const apiUrl = `https://ekyc.finovo.tech:5555/api/v1/esign/register/callback?phone=${phoneNumber}&documentId=${documentId}&isrekyc=${isRekyc}`;
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add any additional headers if required
            },
            // You can add a body here if it's a POST request
          });

          if (response.ok) {
            // API call successful
            console.log('API call successful');
            setShowThankYou(true);
            // history.replace('/legality/callback');
            // You can handle the response data here
          } else {
            // Handle error cases
            console.error('API call failed');
          }
        } catch (error) {
          // Handle network errors
          console.error('Network error:', error);
        }
      }
    }
  };

  useEffect(() => {
    reDirFn();
  }, []);

  const handleChange = (event) => {
    // Get the value entered by the user
    const inputValue = event.target.value;
  
    // Limit the input to 10 characters
    const limitedValue = inputValue.slice(0, 10);
  
    // Update the state with the limited value
    setPhoneNumber(limitedValue);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event, value) => {
    event?.preventDefault();
    // Perform validation
    const phone = value ?? phoneNumber;
    const regex =
      /^\+?([0-9]{1,3})?\s?\(?([0-9]{3})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const isValid = regex.test(phone);
    if (isValid) {

      props.handlePostRequest({ value: phone }, `otp/send?type=SMS`);

      setErrorMsg("");
      setValid(isValid);
    } else {
      setErrorMsg("* Invalid Phone Number");
    }
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest(
        { phone: phoneNumber, otp: otp },
        `otp/verify-mobile`
      );
    } else {
      setOtpErrorMsg("Invalid OTP");
    }
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
    setOtpErrorMsg("");
  };
  const handleEditNumber = () => {
    setValid(false);
  };
  return (
    <>
      <div>
        {showThankYou && <ThankYou />}
      </div>

      {!showThankYou && (<div className="h-screen">
        {/* navbar */}
        <section className="flex bg-white justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
          <div className="">
            <p className="absolute bottom-0 left-0 sm:hidden md:block md:text-xs lg:text-sm md:mt-10 text-black ">
              NOTE: NIRMAN SHARE BROKERS PVT LTD IS DOING CLIENT  BASED<br />
              TRADING AND PRO ACCOUNT TRADING.
            </p>
            <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
          </div>
          <div>
            <p className="text-red font-bold sm:text-xl md:text-2xl">LOGIN</p>


          </div>
        </section>
        {/* navbar end */}
        <form className="flex sm:flex-col bg-cream sm:h-[80vh] md:h-[50vh] md:flex-row md:justify-between lg:justify-around md:items-center">
          <div className="text-blue sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl xxl:text-6xl">
            <Loader open={loading} />
            <div className="sm:p-10 sm:hidden lg:block">
              <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Nirman </p>
              <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Demat Account in</p>
              <p className="font-bold tracking-wider">just 5 minutes</p>
              <p className="text-xs md:mt-16 text-black absolute bottom-0 left-0 sm:hidden md:visible">
                NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED
                TRADING AND PRO ACCOUNT TRADING.
              </p>
              <p className="text-sm md:mt-10 text-black">
                NIRMAN :- “NIRMAN HOUSE” 8, Zone - 1, M. P. Nagar, Bhopal - 462011. (Ph :- 0755-43 11 111)</p>
            </div>
            <div className="sm:p-10 lg:hidden ">
              <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Nirman Demat Account in just 5 minutes</p>
              {/* <p className="sm:hidden md:block text-xs md:mt-16 text-black absolute bottom-0 left-0 sm:hidden md:visible">
                NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED
                TRADING AND PRO ACCOUNT TRADING.
              </p> */}
              <p className="sm:hidden md:block text-sm md:mt-10 text-black">
                NIRMAN :- “NIRMAN HOUSE” 8, Zone - 1, M. P. Nagar, Bhopal - 462011. (Ph :- 0755-43 11 111)</p>
            </div>
          </div>
          {/* Right Container */}
          <div className="m-5">
            <div className="rounded-2xl text-white bg-red">
              <div>
                {!valid && (
                  <>
                    <div className="login-container p-9 max-[998px]:p-5">
                      <div className="mb-3">
                        <p className="text-2xl">
                          Enter your Mobile Number
                        </p>
                      </div>
                      <div className="mb-2">
                        <input
                          autoFocus={true}
                          type="number"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={handleChange}
                          placeholder="Enter mobile no."
                          className="p-3 rounded-full spin-button-none text-black lg:w-[300px]"
                        />
                      </div>
                      <p className="text-white mb-2">{errorMsg}</p>
                      <div className="flex">
                        <input type="checkbox" checked className="me-4" />
                        <div className="text-xs lg:hidden">
                          I agree to receive communication from Nirman Pvt. Ltd.
                          through SMS, Whatsapp, Email, and Calls.
                        </div>
                        <div className="text-xs sm:hidden lg:block">
                          I agree to receive communication from Nirman Pvt. Ltd.<br />
                          through SMS, Whatsapp, Email, and Calls.
                        </div>
                      </div>
                      <br />
                      <RButton
                        handleButtonClick={handleSubmit}
                        buttonName="Send otp"
                        bgColor="bg-navy"
                        externalClassName="p-2 text-white"
                        type={"submit"}
                      />
                    </div>
                  </>
                )}
                {valid ? (
                  <>
                    <div className="p-9">
                      <p className="sm:text-xl md:text-2xl">
                        Enter the OTP sent <br />to your mobile no.
                      </p>
                      <div className="flex my-3">
                        <p className="opacity-70">{phoneNumber}</p>
                        <div onClick={handleEditNumber}>
                          <RImg
                            innerImgClassName="w-[20px]"
                            externalClassName="ml-4"
                            src={EditButton}
                            alt="edit"
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        <input
                          autoFocus
                          type="number"
                          name="otp"
                          id="otp"
                          value={otp}
                          onChange={handleOtpChange}
                          placeholder="Enter otp"
                          className="p-3 rounded-full spin-button-none text-black md:w-[300px]"
                        />
                      </div>
                      <div className="my-3">
                        <p onClick={handleSubmit} className="cursor-pointer">
                          Resend
                        </p>
                      </div>
                      <RButton
                        handleButtonClick={handleOtpSubmit}
                        buttonName="Continue"
                        bgColor="bg-navy"
                        externalClassName="p-2 text-white"
                        type={"submit"}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <p className="md:hidden px-10 text-xs mt-6 text-black absolute bottom-0 left-0 sm:hidden md:visible">
            NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED TRADING AND PRO
            ACCOUNT TRADING.
          </p>
          <p className="md:hidden px-10  text-sm mt-6 text-black">
            NIRMAN :- “NIRMAN HOUSE” 8, Zone - 1, M. P. Nagar, Bhopal - 462011. (Ph :- 0755-43 11 111)</p>
        </form>
      </div>)}
    </>
  );
}

export { MobileLogin };
