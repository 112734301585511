import React, { useState } from "react";
import UserAdmin from "../userAdmin";
import { useNavigate } from "react-router-dom";

function SuperLogin() {
  const [msg, setMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    userpassword: "",
  });

  const navigate = useNavigate()
  const handleLinkClick= (linkTag) => {
    navigate(linkTag)
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      //   setLoading(true);
      const response = await fetch(
        "https://ekyc.finovo.tech:5556/api/v1/login/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: userInfo.username,
            userpassword: userInfo.userpassword,
          }),
        }
      );

      if (response.ok) {
        
        const data = await response.json();
        sessionStorage.setItem("authToken", data.token);
        setMsg(data.msg);
        navigate("/admin")
      } else {
        setMsg("Login Failed");
        
      }
    } catch (error) {
      console.error(error);
      setMsg("An error occurred during login");
    
    } finally {
      //   setLoading(false);
    }
  };

  
    return (
      <>
      <div className="h-[80vh] flex justify-center items-center">
        <form className="max-w-sm border bg-red p-6 rounded-2xl shadow-2xl" onSubmit={handleLogin} >
          <p className="text-center mb-6 text-blue font-bold text-2xl">Super login</p>
          <div className="flex items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block font-bold text-left mb-1 pr-4"
                for="inline-full-name"
              >
                User Id
              </label>
            </div>
            <div className="w-2/3">
              <input
                className="appearance-none border-2 rounded-full w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                id="inline-full-name"
                type="text"
                value={userInfo.username}
                name="username"
                onChange={handleChange}
                placeholder="Enter Your Id"
              />
            </div>
          </div>
          <div className="flex md:items-center mb-6">
            <div className="w-1/3">
              <label
                className="block font-bold text-left mb-1 pr-4"
                for="inline-password"
              >
                Password
              </label>
            </div>
            <div className="w-2/3">
              <input
                className="appearance-none border-2 rounded-full w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                id="inline-password"
                type="password"
                value={userInfo.userpassword}
                name="userpassword"
                onChange={handleChange}
                placeholder="Enter Your Password"
              />
            </div>
          </div>
          <div className="flex items-center">
            
            <div className="">
              <button
                className="border-2 border-lightBlue bg-blue hover:bg-lightBlue focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
        </div>
      </>
    );

  // else {
  //   return (
  //     <>
  //       <UserAdmin />
  //     </>
  //   );
  // }
}

export default SuperLogin;
