import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CameraCapture from "../../components/captureImage";
import Modal from "@mui/material/Modal";
import SignatureCanvas from "react-signature-canvas";
import CameraInstruction from "../../assets/camera/cameraInstruction-1.png";
import RButton from "../../components/rButton";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";
import "./styles.scss";

function UploadPhotoAndSignature(props) {
  const [open, setOpen] = React.useState(false);
  const [imageCapture, setImageCapture] = useState(false);
  const [digiSign, setDigiSign] = useState();
  const [url, setUrl] = useState();
  const [errorLocation, setErrorLocation] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [formData, setFormData] = useState({
    image: "",
    signature: "",
    geolocation: { latitude: "", longitude: "" },
  });
  const [selectedComponent, setSelectedComponent] = useState(null); // New state for component selection

  const handleOpen = () => {
    setOpen(true);
    setSelectedComponent("digitalSignature"); // Set the selected component
  };
  
  const handleClose = () => setOpen(false);

  const validateSignatureFile = (file) => {
    if (!file) return true;

    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      setSignatureError("*Please select a PNG or JPG file for the signature.");
      return false;
    }

    setSignatureError("");
    return true;
  };

  const onChange = (e) => {
    setSelectedComponent("fileUpload"); // Set the selected component
    const files = e.target.files;
    const file = files[0];
  
    if (validateSignatureFile(file)) {
      getCompressedImage(file, 'signature');
    }
  };
  
  const getCompressedImage = async (file, name) => {
    try {
      const compressedImage = await compressImage(file);
      setFormData((prev) => ({ ...prev, [name]: compressedImage }));
    } catch (error) {
      setSignatureError(error.message);
    }
  };
  
  const getLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setFormData((prev) => ({
            ...prev,
            geolocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          }));
        },
        (error) => {
          setErrorLocation(
            "* Please provide location permission as its necessary for KYC"
          );
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData((prev) => ({ ...prev, [name]: reader.result }));
    };
  };

  const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
    setSelectedComponent(null); // Reset the selected component
  };

  const handleCaptureImage = async (val) => {
    console.log('Captured image:', val);
  
    if (val && typeof val === "string" && val.startsWith("data:image/")) {
      const file = dataURLtoFile(val, 'captured_image.jpg');
  
      if (file instanceof Blob) {
        try {
          const compressedImage = await compressImage(file);
          setFormData((prev) => ({ ...prev, image: compressedImage }));
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      } else {
        console.error('Invalid file object:', file);
      }
    } else {
      console.error('Invalid captured image data:', val);
      alert("Please capture a valid image or ensure your camera is working.");
    }
  };
  
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };  

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error('Invalid file type.'));
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 200;
          const MAX_HEIGHT = 200;
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
  
          const compressedImage = canvas.toDataURL("image/jpeg", 0.7);
  
          resolve(compressedImage);
        };
      };
      reader.onerror = (error) => {
        reject(new Error('Error reading file.'));
      };
    });
  };

  const handleClear = () => {
    digiSign.clear();
    setUrl("");
    setSelectedComponent(null); // Reset the selected component
  };

  const handleGenerate = () => {
    setOpen(false);
    setUrl(digiSign.getTrimmedCanvas().toDataURL("image/png"));
    setFormData((prev) => ({
      ...prev,
      signature: digiSign.getTrimmedCanvas().toDataURL("image/png"),
    }));
  };

  return (
    <>
      <div className="">
        {/* navbar */}
        <section className="flex justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
          <div className="">
            <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
          </div>
          <div>
            <p className="text-red font-bold sm:text-xl md:text-2xl ">UPLOAD PHOTO</p>
          </div>
        </section>
        {/* navbar end */}
        <div className="bg-red rounded-t-2xl md:p-16 sm:p-6">
          {errorLocation && (
            <div className="text-white text-center">{errorLocation}</div>
          )}
          <div className="flex justify-between flex-wrap text-white">
            <div className="text-white">
              <p className="sm:text-xl text-white md:text-3xl mb-2">
                Upload Image
              </p>
              <p className="sm:text-xl text-white md:text-3xl mb-3">
                & Signature
              </p>
              <p className="sm:text-lg text-white opacity-60 md:text-2xl my-4">
                Important Image Instructions
              </p>
              <ul className="sm:text-xs md:text-sm text-white ml-4 list-disc">
                <li>Do not wear goggles,cap or anything covering the face</li>
                <li>Capture clear image with a sufficient light</li>
                <li>Not other person should appear in the image</li>
                <li>
                  Distance between the camera should not be more than 0.5 meter
                </li>
                <li>Background of the image should be plan, preferable white</li>
                <li>Do not use the rotate image.Capture only an up-right image</li>
              </ul>
            </div>
            <div className="photo-div flex gap-10 sm:items-center md:flex-col">
              <img className="md:w-full md:h-[25vh] sm:h-[15vh]" src={CameraInstruction} alt="" />
              <div className="md:mt-8">
                {!formData.image && (
                  <RButton
                    buttonName="Enable Camera"
                    handleButtonClick={() => setImageCapture(true)}
                    bgColor="md:text-black"
                    externalClassName="bg-white"
                  />
                )}
                {formData.image && (
                  <>
                    <div>
                      <img
                        className="border-2 w-[250px] h-[200] rounded-lg"
                        src={formData.image}
                        alt=""
                      />
                    </div>
                    <div className="mt-2">
                      <Button
                        sx={{
                          backgroundColor: "#fff",
                          color: "#000",
                          ":hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                        onClick={() => cancelImage("image")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="bg-white h-0.5 w-100 my-8 opacity-40"></div>
          {/* second session */}
          <div className="flex justify-between flex-wrap">
            <div>
              <p className="text-xl text-white">
                Make sure your Signature is clearly visible
                <br />
                <span className="digitalSign">(Choose any one option)</span>
              </p>
              <p className="text-white my-6 me-4">
                Ensure that you upload a clear image of your Signature.
                <br />
                file sixe should be less than 50kb in Image format (.JPG, .JPEG, .PNG)
              </p>
            </div>
            <div className="flex flex-col text-white">
              {selectedComponent !== "digitalSignature" && (
                <input
                  className="pb-2 w-[75%]"
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  name="signature"
                  onChange={onChange}
                />
              )}
              {signatureError && <p className="text-red">{signatureError}</p>}
              {!url && formData.signature && (
                <>
                  <div className="border-2 w-[250px] h-[200] rounded-lg p-2">
                    <img className="" src={formData.signature} alt="signature" />
                  </div>
                  <div className="mt-2">
                    {" "}
                    <Button
                     sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      ":hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                      onClick={() => cancelImage("signature")}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="digitalSign">
            <div className="font-bold text-2xl my-8 text-center text-white">OR</div>
            <div className={`flex justify-around flex-wrap ${selectedComponent !== "fileUpload"?"block":"hidden"} `}>
              <div>
                {selectedComponent !== "fileUpload" && (
                  <>
                  <button
                    className="bg-white font-bold rounded-lg p-2"
                    onClick={handleOpen}
                  >
                    Digital Signature
                  </button>
                      <Modal
                      open={open}
                      disablePortal
                      disableEnforceFocus
                      disableAutoFocus
                      onClose={handleClose}
                    >
                      <div className={`flex justify-center items-center flex-col sm:mt-[200px]`}>
                        <div className="pad-rotate">
                          <SignatureCanvas
                            canvasProps={{
                              width: 400,
                              height: 300,
                              className:
                                "sigCanvas border-2 border-grey rounded-lg bg-white overflow-hidden",
                            }}
                            ref={(data) => setDigiSign(data)}
                          />
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleClear}
                            sx={{ m: 4 }}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerate}
                            sx={{ m: 4 }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </>
                )}
              </div>
              <div>
                <img
                  className="border-2 border-black mt-5 rounded-lg w-[250px] h-[200px] bg-white"
                  src={url}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-grey h-0.5 w-100 my-3"></div>
          <div>
            <Modal open={imageCapture} onClose={() => setImageCapture(false)}>
              <div className="flex justify-start">
                <CameraCapture
                  handleCaptureImage={handleCaptureImage}
                  imageValue={formData.image}
                  onSave={() => setImageCapture(false)}
                />
              </div>
            </Modal>
          </div>
          {formData.image && formData.signature ? (
            <Button
              disabled={Boolean(errorLocation)}
              variant="contained"
              color="success"
              onClick={() =>
                props.handlePostRequest(formData, "details/photo-signature")
              }
            >
              Submit
            </Button>
          ) : (
            formData.image &&
            formData.digiSign && (
              <Button
                disabled={Boolean(errorLocation)}
                variant="contained"
                color="success"
                onClick={() =>
                  props.handlePostRequest(formData, "details/photo-signature")
                }
              >
                Submit
              </Button>
            )
          )}
      
        </div>
      </div>
    </>
  );
}

export { UploadPhotoAndSignature };
