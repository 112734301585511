import React, { useEffect, useState } from "react";
import Tick from "../../assets/succcess.png";

export default function TaxResidence(props) {
  const [taxResidence, setTaxResidence] = useState("NO");
  const handleGenderChange = (e) => {
    setTaxResidence(e.target.value);
    props.handleChange(e.target.name, e.target.value);
  };

  useEffect(() => {
    props.handleChange("taxResidence", taxResidence);
  }, [taxResidence]);
  return (
    <div>
      <div className="flex justify-between text-white mt-4">
        <p>Tax Residence Outside India ?</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>

      <div>
        <label className="mr-3 text-white font-thin">
          <input
            type="radio"
            name="taxResidence"
            value="YES"
            className="mr-1"
            // checked={props.formData.taxResidence === "YES"}
            checked={taxResidence === "YES"}
            onChange={handleGenderChange}
          />
          Yes
        </label>
        <label className="text-white font-thin">
          <input
            type="radio"
            name="taxResidence"
            value="NO"
            className="mr-1"
            // checked={props.formData.taxResidence === "NO"}
            checked={taxResidence === "NO"}
            onChange={handleGenderChange}
          />
          No
        </label>
      </div>
    </div>
  );
}
