import React, { useState , useEffect} from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";

const FinancialProof = ({ data ,updateRejectData }) => {
  const { loading, postData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            financialProof: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const handleApiCall = async () => {
    if (remark?.length > 10) {
      try {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            FINANCIAL_PROOF: remark,
          }
        );
        toast.success("Rejected successfully");
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>FINENCIAL PROOF</b>${remark}`
    updateRejectData({ FINANCIAL_PROOF: newRem });
  }

  useEffect(() => {
    const storedRemark = localStorage.getItem("finentialProof");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("finentialProof", remark);
  }, [remark]);
 

  return (
    <div>
      <Loader open={loading} />
      <ToastContainer />
      <div className="document-container">
      {data?.userInformation?.financialProofDocument ? (
          data.userInformation.financialProofDocument.startsWith("data:image") ? (
            <img src={data.userInformation.financialProofDocument} alt="" />
          ) : (
            <PdfPreview base64String={data.userInformation.financialProofDocument} />
          )
        ) : ""}
      </div>
    </div>
  );
};

export default FinancialProof;
