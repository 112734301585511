import React,{useState} from "react"
import Lion from "../../assets/verification/lion.svg"
import Flag from "../../assets/verification/flagText.svg"
import QR from "../../assets/verification/qr.png"
import Bharat from "../../assets/verification/bharatText.svg"
import "./styles.scss"
import RButton from "../../components/rButton"
import Verified from "../../assets/verification/verified.png"
import EKYC from "../../assets/verification/eKycBack.png"
import KYC from "../../assets/verification/eKyc.png"
import Loader from "../../components/loader"
import RImg from "../../components/rImg"
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png"

function Verification(props) {
  const { aadharResponseData, panCardDataDto } = props.data
  const [load, setLoad] = useState(false)
  // const aadharResponseData = 12
  // const panCardDataDto =12

  const handleClick = () => {
    
    sessionStorage.clear();
    window.location.reload();
    setLoad(true)
    setTimeout(() => {
      setLoad(false)
    },5000)
    
  }
      if (!aadharResponseData) {
        return (
          <>
            <div className="">
            <img src={NirmanLogo} width="100px" alt="nirman logo" />
          </div>
        <Loader open={ load} />
        <div className="email-container flex flex-col gap-20 items-center flex-wrap rounded-2xl p-3 bg-red h-[90vh]">
          <div className="text-black text-center sm:text-[50px] md:text-[100px]">Oop's !</div>
          <div className="bg-blue italic font-bold text-center rounded sm:text-lg md:text-xl border px-2 md:w-[400px] text-white p-4 bg-blue border border-white hover:border-2 cursor-text">
            <p>
              You have uploaded aadhar details previoiusly
              Kindly try another <br /> aadhar !
            </p>
            <button type="submit" className="bg-skyBlue mt-10 text-black p-2 rounded-lg border hover:border-2 border-white cursor-pointer"
            onClick={handleClick}
            >submit</button>
          </div>
        </div>
          </>
        )
      }
    

  return (
    <>
      <div className="h-screen">
      {/* navbar */}
      <section className="flex justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
        <div className="">
          <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
        </div>
        <div>
          <p className="text-red font-bold sm:text-xl md:text-2xl">
            VERIFICATION
          </p>
        </div>
      </section>
      {/* navbar end */}
      <div className="verification-page bg-red flex flex-row justify-between rounded-t-2xl">
        <div className="left-head w-[50%] flex  items-center">
          <div
            className="verified-left flex flex-col justify-center px-12
          py-16"
          >
            <p className="left-up text-blue font-bold text-3xl  flex items-center gap-2">
              Verified{" "}
              <RImg
                src={Verified}
                alt="ryzKyc"
                width="35px"
                externalClassName="flex justify-center items-center"
                innerImgClassName="verified-tick"
              />
            </p>
            <p className="left-mid bg-silver text-xl text-blue font-bold opacity-60">
              Aadhar & PAN
            </p>
            <div className="web-btn pt-6 sm:hidden md:block">
              <RButton
                buttonName="Proceed"
                handleButtonClick={() => props.handleKycStatus("PERSONAL_INFO")}
                bgColor="bg-navy"
                externalClassName="verified-btn text-white"
              />
            </div>
          </div>
        </div>
        <div className="right-head w-[50%] flex flex-col justify-center items-center">
          <div className="right-box border border-white p-4 rounded-xl w-[100%]  my-10 mr-2 ">
            <div className="aadharPan-wrapper flex flex-col w-full">
              <div className="aadhar-card w-[100%] border rounded-lg bg-white mb-4 shadow-2xl">
                <div className="flex justify-around mb-2 ">
                  <img src={Lion} alt="" width="8%" />
                  <img src={Flag} alt="" width="70%" />
                </div>
                <div className="flex">
                  <div className="aadhar-image m-2">
                    <img
                      src={`data:img/jpeg;base64, ${
                        aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                      }`}
                      alt="Aadhar card of user"
                      className="border"
                      width="80px"
                    />
                  </div>
                  <div className="aadhar-info font-bold">
                    <p>Name: {aadharResponseData?.name ?? ""}</p>
                    <p>DOB: {panCardDataDto?.dob ?? ""}</p>
                    <p>Gender: {panCardDataDto?.gender ?? ""} </p>
                  </div>
                </div>
                <div className="aadhar-no text-center my-3 font-bold text-xl">
                  {aadharResponseData?.maskAadharNumber ?? ""}
                  <hr className="text-red" />
                </div>
                <div className="text-center font-bold">
                  आधार - आम आदमी का अधिकार
                </div>
              </div>
              <div className="pan-card w-[100%] border rounded-lg shadow-2xl">
                <div className="flex justify-around">
                  <div className="">
                    <p className=" pan-heading1 text-sm text-center">
                      आयकर विभाग
                    </p>
                    <p className="pan-heading ">INCOME TAX DEPARTMENT</p>
                  </div>
                  <img src={Lion} alt="lion" width="8%" />
                  <img src={Bharat} alt="" width="40%" />
                </div>
                <div className="flex">
                  <img
                    src={`data:img/jpeg;base64, ${
                      aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                    }`}
                    alt="Pan card of user"
                    width="100px"
                    height="100px"
                    className="pan-image border ml-2"
                  />
                  <div className="text-center w-[60%]">
                    <p className="pan-no font-bold">PAN CARD NUMBER</p>
                    <span className="font-bold ">
          
                      {panCardDataDto?.panNumber ?? ""}
                    </span>
                  </div>
                </div>
                <div className="aadhar-info ml-2">
                  <p>
                    NAME:{" "}
                    <span className="font-bold">
                      {" "}
                      {panCardDataDto?.name ?? ""}
                    </span>
                  </p>
                  <p>
                    FATHER NAME:{" "}
                    <span className="font-bold">
                      {" "}
                      {aadharResponseData?.careOf ?? ""}
                    </span>
                  </p>
                  <p>
                    Date Of Birth:
                    <span className="font-bold">
                      {" "}
                      {panCardDataDto?.dob ?? ""}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-btn w-[100%] ml-12  mb-6 md:hidden">
            <RButton
              buttonName="Proceed"
              handleButtonClick={() => props.handleKycStatus("PERSONAL_INFO")}
              bgColor="bg-navy"
              externalClassName="verified-btn text-white"
            />
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export { Verification }
