import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tick from "../../assets/succcess.png";

export default function FatherName(props) {
  const handleChange = (event) => {
    props.handleChange(event.target.name, event.target.value);
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <div className="flex justify-between mt-4">
          <p className="mb-3 text-white">Father Name</p>
          <div>
            <img src={Tick} alt="" width="30px" height="10px" />
          </div>
        </div>
        <input
          type="text"
          onChange={handleChange}
          name="fatherName"
          placeholder="Mr."
          className="border-2 p-4 border-black w-56 rounded text-black"
        />
      </FormControl>
    </Box>
    // {/* <TextField
    //       sx={{ border: "2px solid white", mt:1 }}
    //       className="w-56 rounded text-white"
    //       id="outlined-basic"
    //       onChange={handleChange}
    //       name="fatherName"
    //       variant="outlined"
    //     /> */}
  );
}
