import React from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ADMIN_BASE_URL } from "../../constant/constants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../assets/nirmanData/nirmanLogo.png";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSearch } from "react-icons/ci";
import { FILTER_DATA } from "../../constant/MapperConstant";
import useSessionStorage from "../../hooks/useSession";
const ClientListing = () => {
  const navigation = useNavigate();
  const [data, setData] = React.useState({ totalPage: 1 });
  const [token, setToken] = useSessionStorage("token", "");
  const [filteredData, setFilteredData] = React.useState([]);
  const [kycStage, setKycStage] = useSessionStorage("kycStage", "");
  const [searchData, setSearchData] = React.useState({
    search: "",
    page: 1,
    filter: "",
  });
  const { loading, fetchData } = useApi();
  const [aadharXml, setAadharXml] = React.useState("");
  async function getClientList() {
    const rmName = sessionStorage.getItem("name");
    try {
      const data = await fetchData(
        `admin/users/filter?search=${searchData.search}&status=${searchData.filter}&page=${searchData.page}&rmname=${rmName}`,
        {},
        ADMIN_BASE_URL
      );
      setData(data.pageDetail);
      setFilteredData(data?.userAdminPageContent ?? []);
    } catch (e) {
      toast.error("Error while fetching data");
    }
  }

  React.useEffect(() => {
    getClientList();
  }, [searchData.page, searchData.search, searchData.filter]);

  React.useEffect(() => {
    const pathName = window?.location?.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const documnetIds = urlParams.get("documentId");
    if (pathName === "/freshLead/*") {
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/freshLead/*", "");
      window.history.replaceState({}, document.title, newURL);
    }
})

  const getDataTime = (epoch) => {
    const date = new Date(+epoch);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const handleApiCalls = async (url, BASE_URL, successMsg, errorMsg) => {
    const baseUrl = BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      toast.success(successMsg);
      return data;
    } catch (e) {
      toast.error(errorMsg);
      throw e; // Re-throw the error to be caught by the calling function
    }
  };

  const handleRedirectToDetails = (clientId, phoneNumber) => {
    navigation(`/client/${phoneNumber}`, {
      state: { clientId: clientId, phoneNumber: phoneNumber },
    });
  };

  const handleFilter = (value) => {
    if (value === "ALL") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: "" }));
    } else {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim() ?? "";
    if (
      (e.key === "Enter" && searchValue) ||
      (!searchValue && searchData.search)
    ) {
      setSearchData((prev) => ({ ...prev, search: searchValue, page: 1 }));
    }
    e.preventDefault();
  };
  const handleSaveClick = () => {
    // Create a Blob with the state data
    const blob = new Blob([aadharXml], { type: "text/plain" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "aadharXml.xml";

    // Append the link to the body and click it
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);

    console.log("State saved successfully!");
  };
  const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
      <ul className="pagination bg-cream px-2 font-bold over-flow-scroll flex">
        {pages.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? "active" : ""}`}
          >
            <button
              className="page-link bg-blue text-white px-3 py-2 "
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    );
  };
  const LogOut =() =>{
    // sessionStorage.clear();
    setKycStage("RM_LOGIN")
    window.location.reload();
  }

  return (
    <div className="h-screen">
      <nav className="navbar navbar-light bg-light">
        <div className="flex justify-between items-center">
          <div>
          <img src={Logo} className=" mb-4" alt="..." width="70px" />
          </div>
          <Link to="/open-account">
          <div className="border border-dark p-2 rounded-pill">
            Open New Account
          </div>
          </Link>
          <div>
            <Link to="/rm-login">
          <button className='sign-out m-auto p-2 rounded mr-10'  onClick={LogOut}>Sign Out</button>
            </Link>
          </div>
        </div>
      </nav>
      {/* <div>
        <img src={ Logo} width="100px" />
      </div> */}
      <ToastContainer />
      <div className="flex justify-center gap-5 items-center tab-color ">
        <div className="flex items-center">
          <input
            type="number"
            onKeyUpCapture={handleSearch}
            onWheel={(e) => e.target.blur()}
            className="w-100 py-2 px-2 rounded-full rounded-e-none  border border-r-0 "
            placeholder="Enter Mobile Number"
          />
          <div className="bg-white border border-l-0 p-2.5 rounded-full rounded-s-none">
            <CiSearch className="text-xl" />
          </div>
        </div>
        <div className="my-3">
          {/* <select
            className={`border rounded p-3 ${
              searchData.filter ? "success" : "info"
            }`}
            onChange={(e) => handleFilter(e.target.value)}
          >
            <option value="" className="p-2">
              {searchData?.filter ? searchData?.filter : "Select Filter"}
            </option>
            {FILTER_DATA.map((item, idx) => (
              <option key={item.id} value={item.id} className="p-2">
                {item.text}
              </option>
            ))}
          </select> */}
          <Link to="/freshLead">
            <button className="border-2 border-black rounded bg-red text-white px-3 py-2 text-xl">
            Fresh leads
          </button>
          </Link>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="m-2 w-full">
          <thead className="border-b">
            <tr className="">
              <th>Sr. No</th>
              <th>Action</th>
              <th>View Details</th>
              <th>Reference Code</th>
              <th>Branch</th>
              <th>Client Code</th>
              <th>PAN</th>
              <th>STATUS</th>
              <th>Name</th>
              <th>Mob No.</th>
              <th>Payment Status</th>
              <th>Entry Time</th>
            </tr>
          </thead>
          {filteredData && filteredData.length ? (
            <tbody className="text-center">
              {filteredData.map((row, idx) => (
                <tr key={row.phoneNumber} className="border">
                  <td className="border">{idx + 1}</td>
                  <td className="border">
                    <Link to="/open-account" > 
                      <button className="bg-blue text-white py-3 px-2 rounded">
                        Continue
                      </button>
                    </Link>
                  </td>
                  <td className="border">
                    <button
                      className="bg-blue text-white px-2 py-3 m-2 rounded"
                      onClick={() =>
                        handleRedirectToDetails(row.clientCode, row.phoneNumber)
                      }
                    >
                      View Details
                    </button>
                  </td>
                  <td className="border">{row.referenceCode}</td>
                  <td className="border">{row.branch}</td>
                  <td className="border">{row?.clientCode ?? "N/A"}</td>
                  <td className="border">{row?.panCard ?? "N/A"}</td>
                  <td className="border">{row.kycStatus}</td>
                  <td className="border">{row.customerName}</td>
                  <td className="border">{row.phoneNumber}</td>
                  <td className="border">{row?.paymentStatus ?? "N/A"}</td>
                  <td className="border">{getDataTime(row?.dateEntryTime)}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <p className="no-data-available">No Data Available</p>
          )}
        </table>
      </div>
      <CustomPagination
        totalPages={+data?.totalPage ?? 1}
        currentPage={searchData.page}
        onPageChange={(page) =>
          setSearchData((prev) => ({ ...prev, page: page }))
        }
      />
    </div>
  );
};

export default ClientListing;
