import "./App.css";
import KYC from "./kyc/index";

function App() {
  return (
    <div className="App">
      <KYC/>
    </div>
  );
}

export default App;
