import React, { useState } from "react";
import RmLogIn from "../../rmLogin";
import Logo from "../../assets/nirmanData/nirmanLogo.png"
import "./styles.scss";
import { Link } from "react-router-dom";

function RmIdLogin() {

  return (
    <>
      <main className="bg-cream h-screen flex flex-col justify-center items-center relative">
        <img src={Logo} className="absolute top-0 left-0 sm:w-[70px] lg:w-[100px]" />
        <h1 className="text-3xl font-bold p-4 ">RM Login <hr/></h1>
        <div className="bg-gradient-to-r from-red via-lightBlue to-navy rounded h-1/2 flex justify-around w-[50%]">
          <Link to="/admin" className="m-auto">
              <button className="p-3 bg-white  rounded-lg font-bold hover:border">My Account</button>
          </Link>
              <Link to="/open-account" className=" m-auto">
                <button
                  className="p-3 bg-white hover:border rounded-lg font-bold"
                  >
                  Open New Account
                </button>
              </Link>
            </div>
      </main>
    </>
  );
}

export default RmIdLogin ;
