import React, { useState , useEffect} from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const BankProof = ({ data , updateRejectData }) => {
  const { loading, error, postData, fetchData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");
  

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            bankProof: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const handleApiCall = async () => {
    try {
      if (remark?.length > 10) {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            BANK_PROOF: remark,
          }
        );
      }
      toast.success("Rejected successfully");
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>BANK PROOF</b>${remark}`
    updateRejectData({ BANK_PROOF: newRem });
  }


  useEffect(() => {
    const storedRemark = localStorage.getItem("bankProof");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("bankProof", remark);
  }, [remark]);
 


  return (
    <div>
      <Loader open={loading} />
      <ToastContainer />
      <div className="document-container border-2 border-cream">
      {data?.bankDetails?.bankValidationProof ? (
          data.bankDetails.bankValidationProof.startsWith("data:image") ? (
            <TransformWrapper>
            <TransformComponent>
              <img src={data.bankDetails.bankValidationProof} alt="test" />
            </TransformComponent>
          </TransformWrapper>
          ) : (
            <PdfPreview base64String={data.bankDetails.bankValidationProof} />
          )
        ) : ""}
      </div>
    </div>

  );
};

export default BankProof;
