import React from "react";
import { Navbar } from "../../layout/navbar";
import Verification from "../../layout/Verification";
import ClientListing from "../clients";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import RmLogIn from "../../../rmLogin";
import FreshLeads from "../clients/fetchFreshData";
import RmLeads from "../clients/rmLeads";


const Home = () => {

  
  return (
    <div className="bg-secondary">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="*" element={<ClientListing />} />
          <Route path="/client/:clientId" element={<Verification />} />
          <Route path="/open-account" element={<RmLogIn />} />
          <Route path="/freshLead" element={<FreshLeads />} />
          {/* <Route path="/rm-acc" element={<RmLeads />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Home;
