import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Loader from "../../components/loader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useApi from "../../hooks/useApi";
// import "./styles.scss";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";
import Login from "../../assets/mobileHome/login.png";
import RButton from "../../components/rButton";
import EKYC from "../../assets/mobileHome/eKyc.png";
import Ekyc from "../../assets/kyc/ekycLogo.png";
import EditButton from "../../assets/email/editButton.png";
import RImg from "../../components/rImg";

function MobileLogin(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();
  const reDirFn = async () => {
    const pathName = window.location.pathname;
    if (pathName === "/legality/callback") {
      const urlParams = new URLSearchParams(window.location.search);
      const phoneNumber = urlParams.get("phoneNumber");
      const isBackoffice = urlParams.get("backoffice");
      if (phoneNumber && phoneNumber.length === 10) {
        setPhoneNumber(phoneNumber);
        handleSubmit(undefined, phoneNumber);
      }
    }
  };

  useEffect(() => {
    reDirFn();
  }, []);

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event, value) => {
    event?.preventDefault();
    // Perform validation
    const phone = value ?? phoneNumber;
    const regex =
      /^\+?([0-9]{1,3})?\s?\(?([0-9]{3})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const isValid = regex.test(phone);
    if (isValid) {

      props.handlePostRequest({ value: phone }, `otp/send?type=SMS`);

      setErrorMsg("");
      setValid(isValid);
    } else {
      setErrorMsg("* Invalid Phone Number");
    }
  };
  const handleOtpSubmit = (event) => {
    const name = sessionStorage.getItem("name")
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest(
        { phone: phoneNumber, otp: otp },
        `otp/verify-mobile?rm=${name}`
      );
    } else {
      setOtpErrorMsg("Invalid OTP");
    }
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
    setOtpErrorMsg("");
  };
  const handleEditNumber = () => {
    setValid(false);
  };
  return (
    <div className="h-screen">
      {/* navbar */}
      <section className="flex bg-white justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
        <div className="">
          <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
        </div>
        <div>
          <p className="text-red font-bold sm:text-xl md:text-2xl">LOGIN</p>
        </div>
      </section>
      {/* navbar end */}
      <form className="flex sm:flex-col bg-cream h-[50vh] md:flex-row md:justify-between lg:justify-around md:items-center">
          <div className="text-blue sm:text-lg md:text-xl lg:text-2xl xl:text-3xl xxl:text-4xl">
            <Loader open={loading} />
            <div className="sm:p-10 sm:hidden lg:block">
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Trading and Demat Account<hr className="mt-2"/></p>
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">How to open an account with us:</p>
            <p className="font-bold tracking-wider">just 5 minutes</p>
            <p className="text-xs md:mt-16 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED <br /> TRADING AND PRO
              ACCOUNT TRADING.
            </p>
            </div>
            <div className="sm:p-10 lg:hidden ">
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Nirman Demat Account in just 5 minutes</p>
            <p className="sm:hidden md:block text-xs md:mt-16 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED TRADING AND PRO
              ACCOUNT TRADING.
            </p>
            </div>
        </div>
        {/* Right Container */}
        <div className="m-5">
          <div className="rounded text-white bg-red">
            <div>
              {!valid && (
                <>
                   <div className="login-container sm:p-4 md:p-5">
                    <div className="mb-3">
                      <p className="text-2xl">
                        RM/AP Login
                      </p>
                    </div>
                    <div className="mb-2">
                      <input
                        autoFocus={true}
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        placeholder="Enter mobile no."
                        className="p-3 rounded-lg spin-button-none text-black lg:w-[300px]"
                      />
                    </div>
                    <p className="text-white mb-2">{errorMsg}</p>
                    <div className="flex">
                      <input type="checkbox" checked className="me-4" />
                      <div className="text-xs lg:hidden">
                        I agree to receive communication from Nirman Pvt. Ltd. 
                        through SMS, Whatsapp, Email, and Calls.
                      </div>
                      <div className="text-xs sm:hidden lg:block">
                        I agree to receive communication from Nirman Pvt. Ltd.<br/> 
                        through SMS, Whatsapp, Email, and Calls.
                      </div>
                    </div>
                    <br />
                    <RButton
                      handleButtonClick={handleSubmit}
                      buttonName="Send otp"
                      bgColor="bg-navy"
                      externalClassName="p-2 text-white"
                      type={"submit"}
                    />
                  </div>
                </>
              )}
              {valid ? (
                <>
                  <div className="p-9">
                    <p className="sm:text-xl md:text-2xl">
                      Enter the OTP sent <br/>to your mobile no.
                    </p>
                    <div className="flex my-3">
                      <p className="opacity-70">{phoneNumber}</p>
                      <div onClick={handleEditNumber}>
                        <RImg
                          innerImgClassName="w-[20px]"
                          externalClassName="ml-4"
                          src={EditButton}
                          alt="edit"
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <input
                        autoFocus
                        type="number"
                        name="otp"
                        id="otp"
                        value={otp}
                        onChange={handleOtpChange}
                        placeholder="Enter otp"
                        className="p-3 rounded-full spin-button-none text-black md:w-[300px]"
                      />
                    </div>
                    <div className="my-3">
                      <p onClick={handleSubmit} className="cursor-pointer">
                        Resend
                      </p>
                    </div>
                    <RButton
                      handleButtonClick={handleOtpSubmit}
                      buttonName="Continue"
                      bgColor="bg-navy"
                      externalClassName="p-2 text-white"
                      type={"submit"}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
          <p className="md:hidden px-10 text-xs my-6 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED TRADING AND PRO
              ACCOUNT TRADING.
            </p>
      </form>
    </div>
  );
}

export { MobileLogin };
