import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

const ANNUAL_SALARY_DETAILS_CONSTANTS = [
  { key: "1", value: "Below 1 Lac" },
  { key: "2", value: "1-5 Lac" },
  { key: "3", value: "5-10 Lac" },
  { key: "4", value: "10-25 Lac" },
  { key: "5", value: "0.25-1 Cr" },
  { key: "6", value: ">1 Cr" },
];

export default function NetWorth(props) {
  const [netWorth, setNetWorth] = useState("1");
  const handleChange = (event) => {
    const newValue = event.target.value;
    setNetWorth(newValue);
    props.handleChange(event.target.name, newValue);
  };

  useEffect(() => {
    props.handleChange("netWorth", netWorth);
  }, [netWorth]);

  return (
    <div>
      <div className="flex justify-between mt-4">
        <p className="text-white">Net Worth</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>

      <div>
        <label>
          <select
            className="w-56 text-black py-3.5 rounded px-2 mt-3"
            value={props.formData?.netWorth}
            name="netWorth"
            onChange={handleChange}
          >
            {ANNUAL_SALARY_DETAILS_CONSTANTS.map((item) => (
              <option className="text-black" key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </label>
      </div>
    </div>
  );
}
