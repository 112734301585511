import React, { useState , useEffect} from "react";
import PdfPreview from "../../components/pdfViewer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const Nominee3 = ({ data , updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>THIRD NOMINEE PROOF</b>${remark}`
    updateRejectData({ NOMINEE_THREE: newRem });
  }


  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee3");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee3", remark);
  }, [remark]);
 

  return (
    <div>
      <div className="document-container border-2 border-cream">
        <div className="panImg-container m-auto border-2 border-cream">
        {data?.nomineeDetails[2]?.proofOfAddress ? (
            data?.nomineeDetails[2]?.proofOfAddress.startsWith("data:image") ? (
              // <img src={data?.nomineeDetails[0]?.proofOfAddress} alt="" />
              <TransformWrapper>
            <TransformComponent>
              <img src={data?.nomineeDetails[2]?.proofOfAddress} alt="test" />
            </TransformComponent>
          </TransformWrapper>
            ) : (
              <PdfPreview base64String={data?.nomineeDetails[2]?.proofOfAddress} />
            )
          ) : ""}
        </div>
      </div>
    </div>
  );
};

export default Nominee3;
