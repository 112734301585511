import { useState, useEffect } from "react";
import MaritalStatus from "../../components/maritialStatus";
import QualificationDetails from "../../components/eductionDetails";
import Occupation from "../../components/occupation";
import TaxResidence from "../../components/taxResidence";
import Nationality from "../../components/nationality";
import PoliticallyExposed from "../../components/politicalExposed";
import StockAccused from "../../components/stocksAccused";
import FatherName from "../../components/fatherName";
import MotherName from "../../components/motherName";
import AnnualSalary from "../../components/annualSalary";
import NomineeAdded from "../../components/nomineeAdded";
import TradingExp from "../../components/tradingExp";
import Nominee from "../../components/nominee";
import RButton from "../../components/rButton";
import "./styles.scss";
import NetWorth from "../../components/netWorth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";

const nomineeDataItem = {
  name: "",
  dateOfBirth: "",
  contactNumber: "",
  email: "",
  poiReferenceNumber: "",
  typeOfDocument: "",
  relation: "",
  proofOfAddress: "",
  addressDto: {
    street: "",
    district: "",
    state: "",
    pincode: "",
    country: "",
    houseNumber: "",
  },
  sharePercentage: "",
};

function PersonalDetails(props) {
  const [formData, setFormData] = useState({
    maritalStatus: "",
    spouseName: "",
    occupation: "",
    taxResidence: "",
    nationality: "",
    politicallyExposed: "",
    actionTakenBySebiOrAnyOtherAuthority: "",
    actionTakenBySebiOrAnyOtherAuthorityDescription: "",
    fatherName: "",
    motherName: "",
    annualSalary: "",
    netWorth: "",
    tradingExperience: "",
  });
  const [nomineeData, setNomineeData] = useState({
    nomineeAdded: true,
    listOfNomineeMembersInfo: [{ ...nomineeDataItem }],
  });
  const [nomineeErrorData, setNomineeErrorData] = useState([]);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = Object.entries(formData).map(([key, value]) => {
      if (
        (key === "spouseName" || key === "stockAccusedReason") &&
        value === "" &&
        ((key === "spouseName" && formData["maritalStatus"] === "MARRIED") ||
          (key === "stockAccusedReason" && formData["stockAccused"] === "YES"))
      ) {
        return true;
      }
      return false;
    });
    // for (const key in formData) {
    if (
      formData.fatherName === "" ||
      formData.motherName === "" ||
      formData.tradingExperience === "" ||
      formData.occupation === ""
    ) {
      setError("Please fill in all fields....");
      if (formData.fatherName === "") {
        // alert("Fill the Father's name");
        toast.error("Fill the Father's name");
      }
      if (formData.motherName === "") {
        // alert("Fill the Mother's name");
        toast.error("Fill the Mother's name");
      }
      if (formData.tradingExperience === "") {
        toast.error("Fill the Trading Experience");
      }
      if (formData.occupation === "") {
        toast.error("Fill the Occupation");
      }
      return;
    } else {
      if (
        (nomineeData.nomineeAdded && validateNomineeDetails()) ||
        !nomineeData.nomineeAdded
      ) {
        props.handlePostRequest(
          {
            basicUserInfoDto: {
              ...formData,
              nomineeCheck: nomineeData.nomineeAdded,
            },
            listofNomineeMembersInfo: {
              listOfNomineeInfo: [...nomineeData.listOfNomineeMembersInfo],
            },
          },
          "details/basic-details"
        );
      }
    }
  };

  const validateNomineeDetails = () => {
    const data = nomineeData.listOfNomineeMembersInfo || [];
    const errors = data.map((item, idx) => {
      let errorItem = {};

      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === "object") {
          // Handle address DTO validation
          const addressDto = value || {};
          const errorAddressDto = {};

          Object.entries(addressDto).forEach(([addressKey, addressValue]) => {
            // Implement your specific address validation logic here
            if (!addressValue) {
              errorAddressDto[addressKey] = `${addressKey} is required.`;
              toast.error(`${key}'s ${addressKey} is required.`);
            }
          });

          if (Object.keys(errorAddressDto).length > 0) {
            errorItem[key] = errorAddressDto;
          }
        } else {
          // Handle regular property validation
          if (!value) {
            errorItem[key] = `${key} is required.`;
            toast.error(`${key} is required.`);
          }
        }
      });
      return Object.keys(errorItem).length > 0 ? errorItem : null;
    });

    setNomineeErrorData(errors);

    const sumOfShared = nomineeData.listOfNomineeMembersInfo.reduce(
      (sum, { ...member }) => sum + +member.sharePercentage,
      0
    );

    if (sumOfShared !== 100) {
      toast.error(
        `Shared percentage ${sumOfShared}. Please make sure the sum of shared percentage between nominees must be 100.`
      );
      return false;
    }

    return errors.some((error) => error !== null) ? false : true;
  };
  const handleChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleNomineeRadio = (key, value) => {
    if (value === "YES") {
      setNomineeData((prev) => ({
        listOfNomineeMembersInfo: [{ ...nomineeDataItem }],
        nomineeAdded: true,
      }));
    } else {
      setNomineeData((prev) => ({
        nomineeAdded: false,
        listOfNomineeMembersInfo: [],
      }));
    }
  };
  const handleAddNominee = () => {
    if (nomineeData.listOfNomineeMembersInfo.length < 3) {
      setNomineeData((prev) => ({
        ...prev,
        listOfNomineeMembersInfo: [
          ...prev.listOfNomineeMembersInfo,
          { ...nomineeDataItem },
        ],
      }));
    }
  };

  const handleNomineeChange = (key, val, idx, extraKey) => {
    setNomineeData((prev) => {
      const updatedListOfNomineeMembersInfo = prev.listOfNomineeMembersInfo.map(
        (nominee, index) => {
          if (index === idx) {
            if (extraKey) {
              return {
                ...nominee,
                [extraKey]: {
                  ...nominee[extraKey],
                  [key]: key === "poiReferenceNumber" ? val.toUpperCase() : val,
                },
              };
            } else {
              return {
                ...nominee,
                [key]: key === "poiReferenceNumber" ? val.toUpperCase() : val
              };
            }
          }
          return nominee;
        }
      );

      return {
        ...prev,
        listOfNomineeMembersInfo: updatedListOfNomineeMembersInfo,
      };
    });
  };

  const handleRemoveNominee = (idx) => {
    const tempListOfNomineeMembersInfo =
      nomineeData.listOfNomineeMembersInfo.toSpliced(idx, 1);
    const isNomineeMember = Boolean(tempListOfNomineeMembersInfo.length);
    // debugger;
    setNomineeData((prev) => {
      return {
        nomineeAdded: isNomineeMember,
        listOfNomineeMembersInfo: tempListOfNomineeMembersInfo,
      };
    });
  };

  const renderNominee = () => {
    const { listOfNomineeMembersInfo } = nomineeData;
    return listOfNomineeMembersInfo.map((nominee, idx) => (
      <div
        key={`nominee_${idx}`}
        className="flex flex-col border-2 bg-red border-black p-8 rounded-xl my-2"
      >
        <div className="flex justify-between ">
          <span className="font-bold text-white underline underline-offset-4 mb-2">
            Nominee {idx + 1}
          </span>
          <RButton
            externalClassName=""
            buttonName="X"
            bgColor="bg-white"
            handleButtonClick={() => handleRemoveNominee(idx)}
          />
        </div>
        <Nominee
          key={`nomineeItem_${idx}`}
          data={nominee}
          handleChange={(key, val, extraKey) =>
            handleNomineeChange(key, val, idx, extraKey)
          }
          errorData={nomineeErrorData[idx] || {}}
        />
      </div>
    ));
  };
  return (
    <>
      <div className="">
        <section className="flex justify-between  sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
          <div className="">
            <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
          </div>
          <div>
            <p className="text-red font-bold sm:text-xl md:text-2xl ">
              PERSONAL DETAILS
            </p>
          </div>
        </section>
        {/* navbar end */}
        <div className="personalInfo-box flex flex-col justify-start text-blue font-bold ">
          {/* <p className="text-3xl py-6">Personal Details</p> */}
          <div className="bg-navy p-6 rounded-2xl">
            <MaritalStatus handleChange={handleChange} formData={formData} />
            <QualificationDetails
              handleChange={handleChange}
              formData={formData}
            />
            <Occupation handleChange={handleChange} />
            <TaxResidence handleChange={handleChange} />
            <Nationality handleChange={handleChange} />
            <PoliticallyExposed handleChange={handleChange} />
            <StockAccused handleChange={handleChange} formData={formData} />
            <FatherName handleChange={handleChange} formData={formData} />
            <MotherName handleChange={handleChange} formData={formData} />
            <TradingExp handleChange={handleChange} formData={formData} />
            <NetWorth handleChange={handleChange} formData={formData} />
            <AnnualSalary name={"annualSalary"} handleChange={handleChange} />
            <NomineeAdded
              value={nomineeData.nomineeAdded}
              handleChange={handleNomineeRadio}
            />
            {renderNominee()}
            {nomineeData?.nomineeAdded && (
              <div className={`flex justify-end ${nomineeData?.listOfNomineeMembersInfo?.length >= 3 ? "hidden" : ""}`}>
                <button
                  size={"smalls"}
                  onClick={handleAddNominee}
                  disabled={nomineeData?.listOfNomineeMembersInfo?.length >= 3}
                  className="text-white rounded-xl bg-red px-4 py-2"
                >
                  Add More
                </button>
              </div>
            )}
            <br />
          </div>
            {error && <p className="text-white my-2">(*{error})</p>}
          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              className="bg-navy text-white px-4 py-2 rounded-xl my-2"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export { PersonalDetails };
