import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { extractParamsValue } from "../../utils/functions";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";
import RImg from "../../components/rImg";
import RButton from "../../components/rButton";
import "./styles.scss";
import DigiErr, { DigiLockerBrochure } from "../../kyc/digiError";
import Gif from "./digilockerGif.gif";
import { toast, ToastContainer } from "react-toastify";

function DigiLocker(props) {
  const [codeChallenger, setCodeChallenger] = useState("");
  const { fetchData, loading } = useApi();
  const [digiInfo, setDigiInfo] = useState(false);

  const extractCodeFromURL = () => {
    const { pathname, search, origin } = window.location;

    if (pathname.includes("/call") && search.includes("code=")) {
      const url = `${origin}${pathname}`;
      const newURL = url.replace("/call", "");
      // Update the URL in the browser without refreshing the page
      window.history.replaceState({}, document.title, newURL);
    } else {
      setDigiInfo(true);
    }
  };
  const fetchCodeChallenger = async () => {
    try {
      const data = await fetchData("user/code-challenger");
      await setCodeChallenger(data?.codeChallenger);
      //await setSessionCodeChallenger(data?.codeChallenger);
    } catch (error) { }
  };

  const handleDigiLocker = () => {
    if (codeChallenger) {
      const stateId = "rmsign" + Math.random().toString(36).substring(7);
      sessionStorage.setItem("state", stateId);
      const redirectUri = encodeURIComponent(
        "https://kyc.nirmanbroking.in/call"
      );

      const digiLockerUrl = `https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=JUABE71114&state=${stateId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenger}&code_challenge_method=S256`;

      window.location.href = digiLockerUrl;
    } else {
      toast.info("We are fetching data from DIGILOCKER.  Kindly Wait!!!");
    }
  };

  useEffect(() => {
    const codeValue = extractParamsValue(window.location.href, "code");
    if (codeValue) {
      extractCodeFromURL();
      props.handlePostRequest({ code: codeValue }, "user/digilocker");
    } else {
      fetchCodeChallenger();
    }
  }, []);

  return (
    <>
      <div className="h-screen">
        {/* navbar */}
        <section className="flex justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
          <div className="">
            <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
          </div>
          <div>
            <p className="text-red font-bold sm:text-xl md:text-2xl ">
              VERIFICATION
            </p>
          </div>
        </section>
        {/* navbar end */}
        {digiInfo ? (
          <DigiLockerBrochure />
        ) : (
          <div className="digilocker-page bg-red rounded-t-2xl flex flex-row  shadow-2xl">
            <div className="left-head verification-head w-[50%] flex items-center justify-center ">
              <div
                className="verification-left flex flex-col justify-center md:px-12
          md:py-16"
              >
                <p className="verification-text text-blue font-bold text-3xl flex items-center gap-2">
                  Verification
                </p>
                <p className="left-mid text-xl text-blue font-bold opacity-60">
                  Aadhar & PAN
                </p>
                <div className="web-btn pt-6 sm:hidden md:block">
                  <RButton
                    buttonName="Proceed to Digi Locker"
                    handleButtonClick={handleDigiLocker}
                    disabled={!Boolean(codeChallenger)}
                    bgColor="bg-navy text-white p-3"
                    externalClassName="digiLocker-btn"
                  />
                </div>
              </div>
            </div>
            <Loader open={loading} />
            <div className="w-[50%] right-head flex justify-center shadow-2xl">
              <div
                onClick={handleDigiLocker}
                disabled={!Boolean(codeChallenger)}
                className="digiRight-box border-white md:p-4 rounded-xlcursor-pointer "
              >
                <div className="flex flex-col justify-center items-center  h-[60vh] ">
                  <div>
                    {" "}
                    <RImg
                      src="https://img1.digitallocker.gov.in/assets/img/digiLocker-Medium.png"
                      alt="Sample Image"
                      innerImgClassName=""
                      externalClassName="rounded-xl"
                    />
                  </div>
                  {/* <RImg
                  src={Gif}
                  externalClassName="rounded-xl flex justify-center"
                  innerImgClassName=" w-[400px] h-[380px] rounded-xl mb-4"
                /> */}
                  <div className="mob-btn mb-10 sm:block md:hidden">
                    <div>
                      <RButton
                        buttonName="Proceed to Digi Locker"
                        disabled={!Boolean(codeChallenger)}
                        bgColor="bg-navy"
                        externalClassName="digiMob-btn text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export { DigiLocker };
